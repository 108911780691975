import { createTokenizer, createToken, resolveTokens } from '@solid-primitives/jsx-tokenizer';
import { createVisibilityObserver } from '@solid-primitives/intersection-observer';
import { createResizeObserver, useWindowSize } from '@solid-primitives/resize-observer';
import { createEffect, createSignal, createUniqueId, For, onMount, onCleanup } from 'solid-js';
import createRAF from '@solid-primitives/raf';
import { createMediaQuery } from '@solid-primitives/media';
import { isServer } from 'solid-js/web';
import type { ParentProps } from 'solid-js';

type Props = ParentProps<{
	pixelsPerFrame?: number;
}>;

function parseXFromTranslate(style: string) {
	return parseFloat(style.replace(/translate3d\(/g, '').replace(/,.*/, ''));
}

export function Carousel(props: Props) {
	const tokens = resolveTokens(Tokenizer, () => props.children);
	const [hovering, setHovering] = createSignal(false);
	let parent: HTMLDivElement | undefined;
	const useVisibilityObserver = createVisibilityObserver({ rootMargin: '10px' });
	const [draggable, setDraggable] = createSignal(false);
	const [dragging, setDragging] = createSignal(false);
	let lastX: number | undefined;
	let lastTimestamp: number | undefined;
	let raf: number | undefined;
	let elements: Array<HTMLElement> = [];
	const widths: Record<string, number> = {};
	const [canStart, setCanStart] = createSignal(false);

	const windowSize = useWindowSize();
	const inViewport = useVisibilityObserver(() => parent);
	const prefersReducedMotion = createMediaQuery('(prefers-reduced-motion)');

	const [running, start, stop] = createRAF((timestamp) => {
		const timeDiff = timestamp - (lastTimestamp ?? timestamp);
		updatePositions((timeDiff / 16) * (props.pixelsPerFrame ?? 0.5));
		lastTimestamp = timestamp;
	});

	function updatePositions(distance: number) {
		const toEnd: Array<HTMLElement> = [];
		const toStart: Array<HTMLElement> = [];

		for (let i = 0; i < elements.length; i += 1) {
			const el = elements[i]!;
			const left = parseXFromTranslate(el.style.transform);
			const newLeft = left - distance;
			if (distance > 0 && newLeft < -1 * widths[el.id]!) {
				toEnd.push(el);
			} else if (distance < 0 && newLeft > windowSize.width) {
				toStart.unshift(el);
			} else {
				el.style.transform = `translate3d(${newLeft}px, 0, 0)`;
			}
		}

		if (toEnd.length || toStart.length) {
			// setEls((els) => {
			const newEls = elements.filter((el) => !toEnd.includes(el) && !toStart.includes(el));
			const last = newEls.at(-1)!;
			const startEnd = parseXFromTranslate(last.style.transform) + widths[last.id]!;
			const startStart = parseXFromTranslate(newEls[0]!.style.transform);

			for (let i = 0; i < toEnd.length; i += 1) {
				const el = toEnd[i]!;
				const start = elements.slice(0, i).reduce((memo, el) => widths[el.id]! + memo, startEnd);
				el.style.transform = `translate3d(${start}px, 0, 0)`;
			}

			for (let i = 0; i < toStart.length; i += 1) {
				const el = toStart[i]!;
				const start = toStart.slice(0, i + 1).reduce((memo, el) => memo - widths[el.id]!, startStart);
				el.style.transform = `translate3d(${start}px, 0, 0)`;
			}
			elements = [...toStart, ...newEls, ...toEnd];
		}
	}

	function handleMouseUp() {
		setDraggable(false);
		setDragging(false);
		lastX = undefined;
	}

	function handleMouseMove(e: MouseEvent) {
		if (!draggable()) {
			return;
		}

		setDragging(true);

		if (raf) {
			cancelAnimationFrame(raf);
		}

		requestAnimationFrame(() => {
			updatePositions((lastX ?? e.clientX) - e.clientX);
			lastX = e.clientX;
		});
	}

	function handleTouchMove(e: TouchEvent) {
		if (!draggable()) {
			return;
		}

		setDragging(true);

		raf && cancelAnimationFrame(raf);
		requestAnimationFrame(() => {
			const clientX = e.targetTouches[0]!.clientX;
			updatePositions((lastX ?? clientX) - clientX);
			lastX = clientX;
		});
	}

	let touchEndTimeout: NodeJS.Timeout;
	function handleTouchEnd() {
		setDragging(false);
		touchEndTimeout = setTimeout(() => {
			setHovering(false);
			setDraggable(false);
			lastX = undefined;
		}, 1000);
	}

	onMount(() => {
		let maxHeight = 0;
		let afterTimeout: NodeJS.Timeout;
		createResizeObserver(elements, ({ width, height }, el) => {
			for (let i = 0; i < elements.length; i += 1) {
				const el = elements[i]!;
				const newLeft = elements.slice(0, i).reduce((memo, el) => memo + (widths[el.id] ?? 0), 0);
				el.style.transform = `translate3d(${Math.round(newLeft)}px, 0, 0)`;
			}
			setCanStart(true);
			afterTimeout && clearTimeout(afterTimeout);
			maxHeight = Math.max(height, maxHeight);
			parent!.style.height = `${maxHeight}px`;
			el.style.position = 'absolute';
			widths[el.id] = width;
			afterTimeout = setTimeout(() => {
				maxHeight = 0;
			}, 16);
		});

		if (!isServer) {
			document.addEventListener('mouseup', handleMouseUp);
			document.addEventListener('mousemove', handleMouseMove);
			document.addEventListener('touchmove', handleTouchMove);
			document.addEventListener('touchend', handleTouchEnd);
		}
	});

	onCleanup(() => {
		if (!isServer) {
			document.removeEventListener('mouseup', handleMouseUp);
			document.removeEventListener('mousemove', handleMouseMove);
			document.removeEventListener('touchmove', handleTouchMove);
			document.removeEventListener('touchend', handleTouchEnd);
		}
	});

	createEffect(() => {
		if (canStart() && !prefersReducedMotion() && inViewport() && !running() && !hovering()) {
			start();
		} else if (prefersReducedMotion() || (!inViewport() && !running()) || hovering()) {
			stop();
			lastTimestamp = undefined;
		}
	});

	return (
		<div
			class="group/carousel relative overflow-clip"
			ref={parent!}
			onMouseEnter={() => {
				setHovering(true);
			}}
			onMouseLeave={() => {
				setHovering(false);
			}}
			onFocusIn={() => {
				setHovering(true);
			}}
			onFocusOut={() => {
				setHovering(false);
			}}
			onMouseDown={() => {
				setDraggable(true);
			}}
			onTouchStart={(e) => {
				clearTimeout(touchEndTimeout);
				lastX = e.targetTouches[0]?.clientX;
				setHovering(true);
				setDraggable(true);
			}}
		>
			<ul class="flex snap-x flex-row gap-4 overflow-hidden">
				<For each={tokens()}>
					{(token) => (
						<li
							ref={(el) => elements.push(el)}
							id={token.data.id}
							// eslint-disable-next-line tailwindcss/no-arbitrary-value
							class="relative w-[70%] shrink-0 grow-0 snap-center will-change-transform lg:w-[28%]"
							classList={{ 'pointer-events-none': dragging() }}
						>
							{token.data.children}
						</li>
					)}
				</For>
			</ul>
		</div>
	);
}

const Tokenizer = createTokenizer<Token>({ name: 'Carousel' });

export const CarouselItem = createToken(Tokenizer, (props) => ({ ...props, type: 'item', id: createUniqueId() }));
type Token = ParentProps<{ type: 'item'; id: string }>;
